<template>
  <v-card class="pa-4 mb-4">
    <p class="secondary--text text-h6">Observaciones</p>
    <app-rich-text-component
      v-model="observaciones"
    ></app-rich-text-component>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "ObservacionesComponent",
  computed: {
    ...mapState("seguimientoOrden", ["liquidacion_compra"]),
    observaciones: {
      get() {
        if (this.liquidacion_compra.observaciones == 'null') {
          return '';
        } else {
          return this.liquidacion_compra.observaciones;
        }
      },
      set(value) {
        this.setObservacionesLiquidacion(value);
      },
    },
  },
  methods: {
    ...mapMutations("seguimientoOrden", ["setObservacionesLiquidacion"]),
  },
};
</script>
