<template>
  <v-card class="mb-4 pa-4">
    <p class="text-h6 secondary--text">Histórico de pagos</p>
    <ListadoPagosComponent hideActions />
    <!-- <resumen-pago /> -->
  </v-card>
</template>

<script>
import ListadoPagosComponent from '../ListadoPagosComponent.vue';
// import ResumenPago from "../pagos/ResumenPagoComponent.vue";
export default {
  name: "HistoricoPagoComponent",
  components: {
    ListadoPagosComponent
  },
};
</script>