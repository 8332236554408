<template>
  <v-card class="pa-4 mb-6">
    <p class="text-h6 secondary--text">Multas</p>
    <v-btn @click="showModal = true" class="mb-4" color="secondary" dark v-if="allowEdit"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      class="elevation-0 pb-6"
      hide-default-footer
    >
      <template v-slot:[`item.fecha_hora_movimiento`]="{ item }">
        {{ moment(item.fecha_hora_movimiento).format("DD/MM/YYYY HH:mm a") }}
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(item.monto)
        }}
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <v-tooltip right v-if="allowEdit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="(deleteModal = true), (id_multa = item.id)"
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <dialogoConfirmacion
      :show="deleteModal"
      title="¿Desea remover el registro seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="deleteModal = false"
      @confirm="deleteMulta()"
    />
    <ModalMultasComponent
      @reloadList="getMultas(), getConsolidadoMultas()"
      :showModal="showModal"
      @onClose="closeModal"
      :metricasMultas="metricasMultas"
    />
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import ModalMultasComponent from "./ModalMultasComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
export default {
  name: "MultasComponent",
  components: {
    ModalMultasComponent,
    dialogoConfirmacion,
  },
  props: {
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    deleteModal: false,
    id_multa: null,
    headers: [
      {
        text: "Porcentaje de la multa (%)",
        value: "detalle.porcentaje",
        sortable: false,
        align: "center",
      },
      {
        text: "Fecha y hora",
        value: "fecha_hora_movimiento",
        sortable: false,
      },
      {
        text: "Monto de la multa ($)",
        value: "monto",
        sortable: false,
        align: "center",
      },
      {
        text: "Justificación",
        value: "justificacion",
        sortable: false,
      },
      {
        text: "Opciones",
        value: "opciones",
        sortable: false,
      },
    ],
    items: [],
    showModal: false,
    metricasMultas: {},
  }),
  methods: {
    ...mapMutations("seguimientoOrden", ["setObservacionesLiquidacion"]),
    closeModal() {
      this.showModal = false;
    },
    async getMultas() {
      const { status, data } =
        await this.services.ContratoService.getContratoMultas(
          this.$route.params.idContrato
        );
      if (status === 200) {
        this.items = data;
      }
    },
    async deleteMulta() {
      const { status } =
        await this.services.ContratoService.deleteContratoMulta(this.id_multa);

      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Multa eliminada",
          type: "success",
        });
        this.getMultas();
        this.getConsolidadoMultas();
        this.deleteModal = false;
      }
    },
    async getConsolidadoMultas() {
      const { status, data } =
        await this.services.ContratoService.getConsolidadoMultas(
          this.$route.params.idContrato
        );
      if (status === 200) {
        this.metricasMultas = {
          cantidad_multas: data.cantidad_multas,
          monto_contratado: data.contrato?.monto_adjudicado,
        };
        this.setObservacionesLiquidacion(data.contrato?.observaciones);
      }
    },
  },
  created() {
    this.getMultas();
    this.getConsolidadoMultas();
  },
};
</script>
