<template>
  <div>
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#datosGenerales' }"
      @click="goTo('#datosGenerales')"
    >
      <v-icon color="secondary">mdi-clipboard-text</v-icon>
      Datos Generales
    </v-btn>
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#descripcionObs' }"
      @click="goTo('#descripcionObs')"
    >
      <v-icon color="secondary">mdi-format-list-bulleted</v-icon>
      Descripción de OBS
    </v-btn>
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#documentosProceso' }"
      @click="goTo('#documentosProceso')"
    >
      <v-icon color="secondary">mdi-book-open-variant</v-icon>
      Documentos del proceso
    </v-btn>
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#etapasContrato' }"
      @click="goTo('#etapasContrato')"
    >
      <v-icon color="secondary">mdi-format-list-numbered</v-icon>
      Etapas del contrato
    </v-btn>
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#historicoPagos' }"
      @click="goTo('#historicoPagos')"
    >
      <v-icon color="secondary">mdi-cash-clock</v-icon>
      Histórico de pagos
    </v-btn>
    <!-- <v-btn
      v-if="hideSancionesMultas"
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#sanciones' }"
      @click="goTo('#sanciones')"
    >
      <v-icon color="secondary">mdi-alert</v-icon>
      Sanciones
    </v-btn>
    <v-btn
      v-if="hideSancionesMultas"
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#multas' }"
      @click="goTo('#multas')"
    >
      <v-icon color="secondary">mdi-clipboard-alert</v-icon>
      Multas
    </v-btn> -->
    <v-btn
      color="transparent"
      tile
      class="elevation-0 d-block secondary--text text-body-1 mb-5 px-0 pr-8"
      :class="{ current: currentTarget === '#observaciones' }"
      @click="goTo('#observaciones')"
    >
      <v-icon color="secondary">mdi-message-reply-text</v-icon>
      Observaciones
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "IndiceComponent",
  props: {
    currentTarget: {
      type: String,
      default: "#datosGenerales",
    },
    hideSancionesMultas: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goTo(target) {
      this.$emit("goTo", target);
    },
  },
};
</script>

<style lang="scss" scoped>
.current {
  border-bottom: 3px solid #111c4e !important;
}
</style>
